import styled from "styled-components";
import { theme } from "themeConfig";

export const Styled404 = styled.div`
  position: relative;
  padding: 150px 0;
  overflow: hidden;

  .main-inner {
    min-height: unset;
  }

  .page-not-found__background {
    position: absolute;
    top: -1155px;
    right: -728px;
    width: 3000px;
    z-index: -10;
    -webkit-transform: rotate(-11deg);
    -ms-transform: rotate(-11deg);
    transform: rotate(339deg);
    overflow: hidden;
    @media screen and (max-width: ${theme.breakpoints.medium}) {
    }
    @media screen and (max-width: ${theme.breakpoints.small}) {
      top: -708px;
      right: -586px;
      width: 1926px;
    }
    span,
    img {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .page-not-found-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page-not-found__title {
    text-align: center;
  }

  .page-not-found__text {
    text-align: center;
    margin: 25px auto;
    max-width: 800px;
  }

  .page-not-found__button {
    margin-top: 10px;
  }
`;
