import React from "react";
import * as api from "../utils/woocommerce/api";
import { Styled404 } from "styles/404.styled";
import { IWPImage } from "@/types/WPImage";
import { WPImage } from "@/components/WPImage/WPImage";
import Link from "next/link";

type Props = {
  acf: {
    page_not_found: {
      title: string;
      text: string;
      button: {
        text: string;
        link: string;
      };
      background_image: IWPImage;
    };
  };
};

const PageNotFound = (props: Props) => {
  const { title, button, text, background_image } = props.acf.page_not_found;

  return (
    <Styled404>
      {background_image && (
        <div className="page-not-found__background">
          <WPImage image={background_image} />
        </div>
      )}
      <div className="main-inner">
        <div className="page-not-found-wrapper">
          {title && <h1 className="page-not-found__title h1">{title}</h1>}
          {text && <p className="page-not-found__text bread-big">{text}</p>}
          {button && (
            <Link href={button.link} passHref>
              <a className="page-not-found__button primary-button button-large">
                {button.text}
              </a>
            </Link>
          )}
        </div>
      </div>
    </Styled404>
  );
};

export async function getStaticProps() {
  const options = await api.products.getOptions();

  return { props: options };
}

export default PageNotFound;
